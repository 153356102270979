body {
    height: 100vh;
    margin: 0;
}

* {
    box-sizing:border-box;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
}

.main-content {
    flex-grow: 1;
    display: flex;
    max-height: calc(100% - 70px);
    border-bottom: 1px solid #7E7F9A;
}

.left-container {
    flex-shrink: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #7E7F9A;
}
.right-container {
    flex-shrink: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
}

/* theming */
.header {background: #ECECFF}
.main-content {background:#ffffff}
.left-container {background:#ffffff}
.right-container {background:#ffffff}
.footer {background:#ffffff}
.chat-input-button {background: #ECECFF}
.chat-input-button:hover {background: #defacc}
.chat-output {background: #ffffff}

.header {
    text-align: center;
    padding:10px;
    color: #000;
    border-bottom: 1px solid #7E7F9A;
}

.chat-output {
    width:100%;
    flex-grow: 1;
    overflow-y: auto;
}

.chat-input-container {
    display: inline-flex;
    width: 100%;
    border-top: 1px solid #7E7F9A;
}

.chat-input-input {
    flex: max-content;
    border: 1px solid rgba(117, 117, 126, 0.45);
    resize:none;
    overflow-y:scroll;
}

.chat-input-input:focus {
    outline: none !important;
    border:1px solid rgba(126, 127, 154, 0.45);
    box-shadow: 0 2px 0 0 rgb(129, 138, 168);
}

.chat-input-button {
    border: 1px solid #a2a4ad;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    height: 100%;
    line-height: 27px;
    min-width: 54px;
    padding: 0 16px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
}

.chat-input-button:hover {
    border-color: #dadce0;
    box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
    color: #202124;
}

.chat-input-button:focus {
    border-color: #4285f4;
    outline: none;
}

.rendered-mermaid {
    white-space: pre-line;
}

.footer {
    text-align: center
}

.chat-message {
    border-top: 1px solid rgba(126, 127, 154, 0.45);
    margin:0;
    padding: 15px 5px;
    line-height:30px;
}

.chat-message-assistant {
    background-color: rgba(233, 233, 253, 0.45);
}

.chat-message-assistant > .chat-message-role {
    background-color: #dcdbdb;
    padding:5px;
    font-weight: normal;
}

.chat-message-user > .chat-message-role {
    background-color: #fae0cc;
    padding:5px;
    font-weight: normal;
}

.profile-button {
    float:right;
    border: 1px solid #a2a4ad;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    height: calc(100% + 20px);
    line-height: 27px;
    margin-top:-10px;
    margin-right:-10px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
    background: #fff;
}

.profile-button:hover {
    background: #defacc;
}

.profile-button > .icon {
    margin-right:5px;
}

.menu-button {
    float:left;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    height: calc(100% + 20px);
    line-height: 27px;
    margin-top:-10px;
    margin-left:-10px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
    background: #ECECFF;
}

.menu-button:hover {
    background: #defacc;
}

.menu > ul {
    list-style:none;
    text-align:center;
    margin:5px;
    background: #fff;
    padding: 5px;
    line-height:24px;
    width:100px;
}

.account-menu-options {
    position: absolute;
    top: 44px;
    right: 0;
    background: #fff;
    border: 1px solid #000;
}

.account-menu-options > ul {
    list-style:none;
    text-align:center;
    padding:0;
    margin:5px;
}

.account-menu-options > ul > li > button {

}

.mermaid-contents {
    margin: 0;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction:column;
}

#mermaid-diagram-container {
    flex-grow:1;
}

.mermaid-options {
    align-self: flex-end;
}

.mermaid-options > button {
    margin: 0;
    background: #ECECFF;
    border: 1px solid #a2a4ad;
    color: #000;
    cursor: pointer;
    font-size: 10px;
    height: 100%;
    line-height: 14px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: pre;
}
.mermaid-options > button:hover {
    background: #defacc;
}

.ducky-icon {
    cursor: pointer;
    margin-left: 35px;
}

.account-page-container {
    padding: 50px;
    text-align: center;
}

.account-page-container > table {

    margin-left: auto;
    margin-right: auto;
}

.account-page-container > table, tr, td{
    border: 1px solid #7E7F9A;
    border-collapse: collapse;
    padding: 10px;
}

.checkout-container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    font-size: 30px;
    max-width: 600px;
}

.checkout-container > .title {
    font-size: 30px;
}

.checkout-container > .description {
    font-size: 20px;
}

.display-linebreak {
    white-space: pre-line;
}

.chatPlanNotification {
    background-color: #e4e3e3;
    font-size: 10px;
    font-style: italic;
    padding: 0 5px;
}

.loggedInText {
    font-size: 10px;
    font-style: italic;
    padding: 0 5px;
}

.terms {
    font-size: 10px;
    font-style: italic;
    padding: 0 5px;
}